<template>
    <div>
        <div v-if="showVideo">
            <div class="mfp-bg mfp-ready" @click.prevent="openVideo"></div>
            <div class="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready" tabindex="-1" style="overflow: hidden auto;">
                <div class="mfp-container mfp-s-ready mfp-iframe-holder">
                    <div class="mfp-content">
                        <div class="mfp-iframe-scaler">
                            <button title="Close (Esc)" type="button" class="mfp-close" @click.prevent="openVideo">×</button>
                            <iframe class="mfp-iframe" :src="videoUrl" frameborder="0" allowfullscreen=""></iframe>
                            </div>
                        </div>
                    <div class="mfp-preloader">Loading...</div>
                </div>
            </div>
        </div>
        <!--====== APPIE HERO PART START ======-->

        <section class="appie-hero-area appie-hero-3-area">
        <div class="container">
            <div class="row  justify-content-center">
                <div class="col-lg-10">
                    <div class="appie-hero-content text-center">
                        <h1 class="appie-title">내 손으로 만드는 <br>앱제작 플랫폼 스윙투앱</h1>
                        <p>
                          앱제작 최고의 플랫폼 스윙투앱<br>
                          노코딩 앱 개발 지식이 없이 앱 제작 서비스<br>
                          앱 운영 & 관리까지 한번에 제공하는 All in One<br>
                        </p>
                        <div class="hero-btns" style="margin-bottom: 30px">
                            <a class="main-btn" href="/auth/register">
                              앱 제작 시작하기
                            </a>
                            <a @click.prevent="openVideo" class="appie-video-popup" ><i class="fas fa-play"></i> Play Video</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </section>

        <!--====== APPIE HERO PART ENDS ======-->
    </div>
</template>

<script>
export default {
    props: {
        title:{
            type:String
        },
        description:{
            type:String
        },
        more_description:{
            type:String
        },
        videoUrl:{
            type:String
        }
    },
    data() {
        return{
            showVideo:false,
        }
    },
    methods: {
        openVideo(){
            this.showVideo = !this.showVideo
        },
    }

}
</script>

<style>

</style>