
import axios from 'axios'

// eslint-disable-next-line camelcase
export const swingMongoApiFn = async (p_url, p_method, p_paramMap) => {
  let settings = {
    method: p_method,
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }

  // eslint-disable-next-line camelcase,eqeqeq
  if (p_method == 'get' || p_method == 'delete') {
    // eslint-disable-next-line camelcase
    if (p_paramMap) {
      for (const [key, value] of Object.entries(p_paramMap)) {
        let word = '&'
        // eslint-disable-next-line eqeqeq
        if (p_url.indexOf('?') == -1) { word = '?' }

        // eslint-disable-next-line camelcase
        p_url += word + encodeURIComponent(key) + '=' + encodeURIComponent(value)
      }
    }

    // eslint-disable-next-line eqeqeq,camelcase
  } else if (p_method == 'post' || p_method == 'put') {
    settings['body'] = JSON.stringify(p_paramMap)
  }

  const response = await fetch(p_url, settings)
  if (!response.ok) {
    // eslint-disable-next-line no-template-curly-in-string
    // throw new Error(message)
  } else {
    const data = await response.json()
    return data
  }
}


export const swingApiFn = async (p_url, p_method, p_paramMap) =>{
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
  // eslint-disable-next-line camelcase,eqeqeq
  if (p_method == 'get' || p_method == 'delete') {
    // eslint-disable-next-line camelcase
    if (p_paramMap) {
      for (const [key, value] of Object.entries(p_paramMap)) {
        let word = '&'
        // eslint-disable-next-line eqeqeq
        if (p_url.indexOf('?') == -1) { word = '?' }

        // eslint-disable-next-line camelcase
        p_url += word + encodeURIComponent(key) + '=' + encodeURIComponent(value)
      }
    }

    // eslint-disable-next-line eqeqeq,camelcase
  }
  // eslint-disable-next-line no-return-await
  return await axios.get(p_url, p_paramMap, { headers })
      .catch(err => {
        console.log(err)
      })
}
