<template>
  
      <!--====== APPIE SERVICES PART START ======-->
    
    <section class="appie-service-area appie-service-3-area pt-35 pb-70" id="service">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <div class="appie-section-title text-center" style="padding-bottom: 0px">
                        <h3 class="appie-title">스윙투앱에서 제공하는 서비스</h3>
                        <p>고객님의 서비스 목적에 맞게 스윙투앱과 앱제작을 시작해보세요.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <div class="appie-single-service appie-single-services-3 text-center wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <div class="icon">
                            <img src="@/assets/images/icon/1.png" alt="">
                        </div>
                        <h4 class="appie-title">홈페이지를 그대로 <br>앱으로 제작하기</h4>
                        <p class="caption-area">
                          홈페이지만 있으면, 웹앱 제작이 가능합니다.<br>
                          복잡한 코딩 없이, 몇번의 클릭만으로 웹앱을 만들어보세요.
                        </p>
                        <a href="#" class="main-btn" >시작하기 <i class="fal fa-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="appie-single-service appie-single-services-3 text-center item-2 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <div class="icon">
                            <img src="@/assets/images/icon/2.png" alt="">
                        </div>
                        <h4 class="appie-title">앱 제작 템플릿과 <br>내 아이디어로 제작</h4>
                        <p class="caption-area">
                          다양한 업종에 맞춘 템플릿으로 전문성을 높이세요.<br>
                          처음부터 누구나 앱 제작 가능<br>
                          홈페이지? 코딩지식? <br>모두 필요없습니다.
                         </p>
                        <a href="#" class="main-btn" >시작하기 <i class="fal fa-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="appie-single-service appie-single-services-3 text-center item-3 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <div class="icon">
                            <img src="@/assets/images/icon/3.png" alt="">
                        </div>
                        <h4 class="appie-title">주문제작 서비스<br>(커스터마이징)</h4>
                        <p class="caption-area">
                          사용자의 아이디어를 바탕으로 완벽한 앱을 제작해드립니다.<br>
                          사용자의 요구 사항에 맞춘 커스터마이징으로 차별화된 앱을 만나보세요.
                        </p>
                        <a href="#" class="main-btn" >시작하기 <i class="fal fa-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="appie-single-service appie-single-services-3 text-center item-4 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="800ms">
                        <div class="icon">
                            <img src="@/assets/images/icon/4.png" alt="">
                        </div>
                        <h4 class="appie-title">플레이스토어 & 앱스토어 <br>유지보수 서비스</h4>
                        <p>복잡하고 어려운 스토어 등록 관리</p>
                        이제 스윙투앱으로 관리하자 <br>외부에서 제작된 앱도<br>
                        스윙투앱의 노하우로 관리하면 간편하고 어렵지 않습니다.
                        <a href="#" class="main-btn" >시작하기 <i class="fal fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!--====== APPIE SERVICES PART ENDS ======-->
</template>

<script>
export default {

}
</script>

<style>

</style>