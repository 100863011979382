<template>
  <div>
        <div v-if="showVideo">
            <div class="mfp-bg mfp-ready" @click.prevent="openVideo"></div>
            <div class="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready" tabindex="-1" style="overflow: hidden auto;">
                <div class="mfp-container mfp-s-ready mfp-iframe-holder">
                    <div class="mfp-content">
                        <div class="mfp-iframe-scaler">
                            <button title="Close (Esc)" type="button" class="mfp-close" @click.prevent="openVideo">×</button>
                            <iframe class="mfp-iframe" src="//www.youtube.com/embed/EE7NqzhMDms?autoplay=1" frameborder="0" allowfullscreen=""></iframe>
                            </div>
                        </div>
                    <div class="mfp-preloader">Loading...</div>
                </div>
            </div>
        </div>
    <!--====== APPIE FUN FACT PART START ======-->

    <section class="appie-fun-fact-area pb-30" id="mycounter">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="appie-fun-fact-box wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <div class="row r">
                            <div class="col-lg-6">
                                <div class="appie-fun-fact-content">
                                    <h3 class="title">국내 1등 모바일 앱제작 빌더 스윙투앱</h3>
                                    <p>누적 어플제작 14만개 이상, 스윙투앱은 수치로 증명합니다.</p>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <div class="appie-fun-fact-item">
                                                <h4 class="title">
                                                    <coun-down-com v-if="showCounters" class="title" :endVal="appCount" />
                                                    <span v-else class="title">0</span><span v-if="showCounters" class="title">{{appCntValK}}</span>
                                                </h4>
                                                <span>제작된 앱 개수</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="appie-fun-fact-item">
                                                <h4 class="title"> <coun-down-com v-if="showCounters" class="title" :endVal="verCount" />
                                                    <span v-else class="title">0</span><span v-if="showCounters" class="title">{{verCntValK}}</span></h4>
                                                <span>만들어진 앱 버전수</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="appie-fun-fact-item">
                                                <h4 class="title"> <coun-down-com v-if="showCounters" class="title" :endVal="devCount" />
                                                    <span v-else class="title">0</span><span v-if="showCounters" class="title">{{devCntValK}}</span></h4>
                                                <span>앱 개발자 수</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="appie-fun-fact-play">
                                    <img src="@/assets/images/fun-fact-thumb.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!--====== APPIE FUN FACT PART ENDS ======-->
  </div>
</template>

<script>
import * as commonLib from '@/assets/js/common'

import CounDownCom from '../Helper/CounDownCom.vue'
export default {
	components: { CounDownCom },
    data() {
        return{
            showVideo:false,
             counterPositions:null,
             showCounters: false,

                appCount:0,
                devCount:0,
                verCount:0,

                appCntValK:'',
                devCntValK:'',
                verCntValK:''

        }
    },
    created() {
        this.getSwingInfoCnt();
    },
     mounted() {
        var rec = document.getElementById('mycounter')
        var currentPosition = rec.offsetTop - document.body.scrollTop;
        this.counterPositions=currentPosition
        window.addEventListener('scroll',()=>{
            var currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
            if(this.counterPositions !== null){
                if(currentScrollPosition + 500>this.counterPositions){
                    this.examplem()
                }
            }

        })
    },
    methods: {
    examplem(){
        this.showCounters = true;
        this.counterPositions=null
      },
        openVideo(){
            this.showVideo = !this.showVideo
        },
        async getSwingInfoCnt(){
            let me = this;

            const url = '/main/get_swing_info_list'
            // const url = `http://www.swing2app.co.kr/main/get_swing_info_list`

            let paramMap = {}
            // eslint-disable-next-line no-return-await
            return await commonLib.swingApiFn(url, 'get', paramMap)
                .then(resultData=>{
                    let result = resultData.data;
                    if(result && result.swingInfoList && result.swingInfoList.length>0){
                        result.swingInfoList.forEach(infoOb => {
                            let lp_val = parseInt(infoOb.code_value);
                            let lp_str = '';
                            if(lp_val>1000){lp_val = parseInt(lp_val/1000);lp_str='k+';}
                            if(infoOb.code_key === "APP_COUNT"){
                                me.appCount = lp_val;
                                me.appCntValK = lp_str;
                            }else  if(infoOb.code_key === "DEV_COUNT"){
                                me.devCount = lp_val;
                                me.devCntValK = lp_str;
                            }else  if(infoOb.code_key === "VER_COUNT"){
                                me.verCount = lp_val;
                                me.verCntValK = lp_str;
                            }

                        })
                    }

                });
    }
    }

}
</script>

<style>

</style>
