<template>
      <!--====== APPIE DOWNLOAD PART START ======-->
    
    <section :class="css_class" class="pt-100 pb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-5">
                    <div class="appie-download-content">
                        <span>{{caption}}</span>
                        <h3 class="title">{{title}} <br> {{more_title}}</h3>
                        <p class="caption-area">{{description}}</p>
                        <ul>
                          <li>
                            <a :href="run_url">
                              <i class="fas fa-arrow-circle-right"></i>
                              <span><span>{{button1_caption}}</span></span>
                            </a>
                          </li>
                          <li v-if="video_url">
                            <a class="item-2" :href="video_url" target="_blank">
                              <i class="fab fa-youtube"></i>
                              <span><span>영상으로 보기</span></span>
                            </a>
                          </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="download-shape-1">
            <img src="@/assets/images/shape/shape-15.png" alt="">
        </div>
        <div class="download-shape-2">
            <img src="@/assets/images/shape/shape-14.png" alt="">
        </div>
        <div class="download-shape-3">
            <img src="@/assets/images/shape/shape-13.png" alt="">
        </div>
    </section>
    
    <!--====== APPIE DOWNLOAD PART ENDS ======-->
</template>

<script>
export default {
    props:{
        caption:{
          type:String,
        },
        title:{
            type:String,
        },
        more_title:{
            type:String,
        },
        description:{
            type:String,
        },
        button1_caption:{
          type:String,
        },
        button2_caption:{
          type:String,
        },
        run_url:{
          type:String,
        },
        video_url:{
          type:String,
        },
        css_class:{
          type:String,
        }
    }

}
</script>

<style>

</style>