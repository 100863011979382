<template>
      <!--====== APPIE FOOTER PART START ======-->

  <section class="appie-footer-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="footer-about-widget">
            <div class="logo">
              <a href="#"><img src="@/assets/images/logo.png" alt="" /></a>
            </div>
            <p>
              앱제작 이제 어렵지 않습니다.<br>
              지금 바로 스윙투앱을 시작해보세요.
            </p>
            <div class="social mt-30">
              <ul>
                <li>
                  <a href="https://www.youtube.com/@swing2app258" target="_blank"><i class="fab fa-youtube"></i></a>
                </li>
                <li>
                  <a href="https://blog.naver.com/swing2app" target="_blank"><i class="far fa-blog"></i></a>
                </li>
                <li>
                  <a href="https://documentation.swing2app.com/" target="_blank"><i class="fas fa-books"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-6">
          <div class="footer-navigation">
            <h4 class="title">링크</h4>
            <ul>
              <li><a href="https://www.swing2app.co.kr/view/dashboard" target="_blank">앱 제작하러 가기</a></li>
              <li><a href="https://blog.naver.com/swing2app" target="_blank">블로그</a></li>
              <li><a href="https://www.swing2app.co.kr/contact" target="_blank">문의하기</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="footer-navigation">
            <h4 class="title">지원 및 도움말</h4>
            <ul>
              <li><a href="https://documentation.swing2app.co.kr" target="_blank">도움말</a></li>
              <li><a href="https://documentation.swing2app.co.kr/faq" target="_blank">FAQ</a></li>
              <li><a href="https://documentation.swing2app.co.kr/terms/user-protocol" target="_blank">서비스 이용약관</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="footer-widget-info">
            <h4 class="title">문의정보</h4>
            <ul>
              <li>
                <a href="#"
                ><i class="fal fa-envelope"></i> help@swing2app.co.kr</a
                >
              </li>
              <li>
                <a href="#"
                ><i class="fal fa-map-marker-alt"></i> 서울시 구로구 디지털로31길 12 태평양물산 2층 12호</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div
              class="
              footer-copyright
              d-flex
              align-items-center
              justify-content-between
              pt-35
            "
          >
            <div class="apps-download-btn">
              <ul>
                <!--                <li>-->
                <!--                  <a href="#"><i class="fab fa-apple"></i> Download for iOS</a>-->
                <!--                </li>-->
                <!--                <li>-->
                <!--                  <a class="item-2" href="#"-->
                <!--                    ><i class="fab fa-google-play"></i> Download for Android</a-->
                <!--                  >-->
                <!--                </li>-->
              </ul>
            </div>
            <div class="copyright-text">
              <p>Copyright © 2015 Swing2App. All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


    
    <!--====== APPIE FOOTER PART ENDS ======-->
</template>

<script>
export default {

}
</script>

<style>

</style>