<template>
  <div>
    <!--====== PRELOADER PART START ======-->

    <!--====== PRELOADER PART ENDS ======-->

    <!--====== OFFCANVAS MENU PART START ======-->

    <sidebar-home-one
      :sidebar="sidebar"
      @toggleSidebar="toggleSidebar"
      :menuItems="navs"
    />

    <!--====== OFFCANVAS MENU PART ENDS ======-->

    <!--====== PART START ======-->

    <header-home-three @toggleSidebar="toggleSidebar" :menuItems="navs" />

    <!--====== PART ENDS ======-->

    <!--====== APPIE HERO PART START ======-->

    <hero-home-three
      title="스윙투앱으로 나만의 앱을 제작하자"
      description=""
      more_description=""
      videoUrl="//www.youtube.com/embed/bcYf9S-ZSO8?autoplay=1"
    />

    <download-home-two
        css_class="appie-download-area webview"
        caption="노코딩! 5분안에 웹사이트를 앱으로"
        title="모바일 웹앱"
        more_title="홈페이지를 앱으로 제작"
        description="홈페이지만 있으면, 웹앱 제작이 가능합니다.
복잡한 코딩 없이, 몇번의 클릭만으로 웹앱을 만들어보세요."
        button1_caption="시작하기"
        run_url="/landing/webview"
        video_url="//www.youtube.com/embed/u2RyoFikaus?autoplay=1"
        style="background: rgb(164 251 201 / 40%);"
    />

    <download-home-two
        css_class="appie-download-area proto"
        caption="노코딩! 다양한 UI 업종별 템플릿으로 5분만에 앱제작"
        title="일반 프로토타입 앱"
        more_title="사용자 에디터 앱제작"
        description="홈페이지가 없다면 일반 프로토타입 앱제작을 시작해보세요.
다양한 디자인 UI, 다양한 업종 템플릿이 제공됩니다."
        button1_caption="시작하기"
        button2_caption=""
        run_url="/landing/prototype"
        video_url="https://www.youtube.com/watch?v=bcYf9S-ZSO8"
        style="background: rgb(231 203 250 / 40%)"
    />

    <download-home-two
        css_class="appie-download-area custom"
        caption="빠르고 신속한 앱 개발 커스터마이징"
        title="커스터마이징앱"
        more_title="주문제작 앱 개발 서비스"
        description="내 앱에만 있는 특별한 디자인, 특색 있는 맞춤 기능
스윙투앱의 커스터마이징으로 차별화된 앱을 만나보세요."
        button1_caption="알아보기"
        button2_caption=""
        run_url="/landing/customization"
        style="background: rgba(190,241,255,0.4)"
    />

    <download-home-two
        css_class="appie-download-area store"
        caption="스토어 유지보수도 스윙투앱으로"
        title="플레이스토어 & 앱스토어"
        more_title="유지보수 서비스"
        description="복잡하고 어려운 스토어 등록 관리 이제 스윙투앱으로 관리하자 외부에서 제작된 앱도 스윙투앱의 노하우로 관리하면 간편하고 어렵지 않습니다."
        button1_caption="알아보기"
        button2_caption=""
        run_url="/landing/storemt"
        style="background: rgba(255,254,199,0.4)"
    />

    <!--====== APPIE HERO PART ENDS ======-->

    <!--====== APPIE SERVICES PART START ======-->

<!--    <services-home-three />-->

    <!--====== APPIE SERVICES PART ENDS ======-->

    <!--====== APPIE FUN FACT PART START ======-->

    <funfact-home-three class="pt-50" />

    <!--====== APPIE FUN FACT PART ENDS ======-->

    <!--====== APPIE ABOUT 3 PART START ======-->

<!--    <about-home-three />-->

    <!--====== APPIE ABOUT 3 PART ENDS ======-->

    <!--====== APPIE SHOWCASE PART START ======-->

<!--    <show-case-home-three />-->

    <!--====== APPIE SHOWCASE PART ENDS ======-->

    <!--====== APPIE DOWNLOAD 3 PART START ======-->

<!--    <download-home-three />-->

    <!--====== APPIE DOWNLOAD 3 PART ENDS ======-->

    <!--====== APPIE BLOG 3 PART START ======-->

<!--    <blog-home-three :blogs="blogs" />-->

    <!--====== APPIE BLOG 3 PART ENDS ======-->

    <!--====== APPIE PROJECT 3 PART START ======-->

<!--    <project-home-three />-->

    <!--====== APPIE PROJECT 3 PART ENDS ======-->

    <sponser-home-two
        title="스윙투앱과 함께한"
        more_title="파트너사"
        description="국내외 많은 고객사들이 스윙투앱과 함께 하고 있습니다."
    />

    <!--====== APPIE FOOTER PART START ======-->

    <footer-home-three />

    <!--====== APPIE FOOTER PART ENDS ======-->

    <!--====== APPIE BACK TO TOP PART ENDS ======-->
    <div class="back-to-top back-to-top-3">
      <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
  </div>
</template>

<script>
import blogImgFour from "@/assets/images/blog-4.jpg";
import blogImgFive from "@/assets/images/blog-5.jpg";
import blogImgSix from "@/assets/images/blog-6.jpg";
import blogImgSeven from "@/assets/images/blog-7.jpg";
import SponserHomeTwo from "../HomeTwo/SponserHomeTwo.vue";
import SidebarHomeOne from "../HomeOne/SidebarHomeOne.vue";
import DownloadHomeTwo from "../HomeTwo/DownloadHomeTwo.vue";
import FooterHomeThree from "./FooterHomeThree.vue";
import FunfactHomeThree from "./FunfactHomeThree.vue";
import HeaderHomeThree from "./HeaderHomeThree.vue";
import HeroHomeThree from "./HeroHomeThree.vue";

export default {
  components: {
    SponserHomeTwo,
    HeaderHomeThree,
    HeroHomeThree,
    // ServicesHomeThree,
    FunfactHomeThree,
    DownloadHomeTwo,
    // AboutHomeThree,
    // ShowCaseHomeThree,
    // DownloadHomeThree,
    // BlogHomeThree,
    // ProjectHomeThree,
    FooterHomeThree,
    SidebarHomeOne,
  },
  data() {
    return {
      sidebar: false,
      navs: [
        {
          name: "Home",
          path : '/'
        },
        {
          name: "서비스",
          childrens: [
            {
              name: "홈페이지로 앱제작",
              path: "/landing/webview",
            },
            {
              name: "템플릿으로 앱 제작",
              path: "/landing/prototype",
            },
            {
              name: "주문제작 서비스",
              path: "/landing/customization",
            },
            {
              name: "앱스토어 & 플레이스토어 유지보수",
              path: "/landing/storemt",
            }
          ],
        },
        {
          name: "가격정책",
          childrens: [
            {
              name: "홈페이지로 앱제작",
              path: "/landing/webview#price",
            },
            {
              name: "템플릿으로 앱 제작",
              path: "/landing/prototype#price",
            },
          ],
        },
        {
          name: "문의하기",
          path: "/contact",
        },
      ],
      blogImgFour: blogImgFour,
      blogImgFive: blogImgFive,
      blogImgSix: blogImgSix,
      blogImgSeven: blogImgSeven,
      blogs: [
        {
          img: blogImgFour,
          title: "How to Improve Your App Store Position",
          date: "July 14, 2022",
          comment_date: "July 14, 2022",
        },
        {
          img: blogImgFive,
          title: "Introducing New App Design for our iOS App",
          date: "July 14, 2022",
          comment_date: "July 14, 2022",
        },
        {
          img: blogImgSix,
          title: "Engineering job is Becoming More interesting.",
          date: "July 14, 2022",
          comment_date: "July 14, 2022",
        },
        {
          img: blogImgSeven,
          title: "20 Myths About Mobile Applications",
          date: "July 14, 2022",
          comment_date: "July 14, 2022",
        },
      ],
    };
  },
  mounted() {
    document.addEventListener("scroll", this.topToBottom);
  },

  methods: {
    topToBottom() {
      const result = document.querySelector(".back-to-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.style.display = "block";
      } else {
        result.style.display = "none";
      }
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
  },
};
</script>

<style>
</style>